.widi-story-heading {
    color: #000;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
}

.pink-divider {
    background-color: #f05;
    height: 3px;
    width: 85px;
}

.pink-heading {
    color: #f05;
    font-size: 50px;
    text-transform: uppercase;
    text-align: center;
}

.pink-heading-sm {
    color: #f05;
    font-size: 40px;
    text-transform: uppercase;
    text-align: left;
    margin: 0;
}

.img-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.widi-img-lg {
    width: 100%;
    height: auto;
    border: 3px solid #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.widi-img-sm {
    width: 50%;
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-color: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.quotation-heading {
    font-size: 36px;
    font-weight: 600;
    font-style: normal;
    line-height: 1.2em;
    margin-top: 40px;
    margin-bottom: 4rem;
    text-align: center;
}

@media (min-width: 767px) and (max-width: 1023px) {
    .widi-story-heading {
        text-align: center;
    }
}

@media (max-width: 767px) {
    .img-wrapper {
        position: relative;
        /* padding-bottom: 3.5rem; */
        margin-bottom: 1.5rem;
    }

    .widi-img-lg {
        width: 100%;
        margin-left: auto;
    }

    .widi-img-sm {
        width: 35%;
        position: absolute;
        bottom: 0;
    }

    .pink-heading-sm {
        font-size: 24px;
    }

    .pink-divider {
        display: none;
    }

    .pink-heading {
        font-size: 32px;
    }

    .widi-story-heading {
        font-size: 20px;
        text-align: center;
    }
}