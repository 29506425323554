.blog-card {
    width: 100%;
    transition: all 0.3s linear;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}

.blog-card img {
    width: 100%;
}

.blog-card:hover img {
    filter: brightness(100%) contrast(125%) saturate(100%) blur(0px) hue-rotate(0deg);
}

.blog-card-body {
    background-color: #ffffff;
    padding: 25px;
}

.blog-heading {
    color: #000000;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 1.2;
}

.blog-desc {
    font-size: 18px;
    line-height: 1.5em;
    color: #777;
    margin-bottom: 20px;
}

.truncate-2 {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
}

.read-more-btn {
    border: solid 2px #f05;
    padding: 15px 60px;
    margin: 0 auto;
    display: block;
    color: #f05;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    background-color: transparent;
    transition: all 0.3s linear;
}

.blog-card:hover .read-more-btn {
    background-color: #f05;
    color: #ffffff;
    transition: all 0.3s linear;
}