.blog-hero {
    background-image: url("../../../public/assets/images/Frame-12.jpg");
    background-size: cover;
    height: 50vh;
    background-position: center 37%;
}

.page-heading {
    color: #FFFFFF;
    font-size: 60px;
    font-weight: 700;
    text-transform: uppercase;
}

.page-para {
    color: #FFFFFF;
    font-size: 18px;
    line-height: 1.5em;
}

.para-width {
    width: 58%;
}

.brand-carousel-bg {
    background-image: url("../../../public/assets/images/bg-featured-logos.png");
    background-size: cover;
    background-position: center;
    padding: 30px 0px;
}

.brand-carousel .item img {
    max-height: 35px;
}

@media (max-width:990px){
    .page-heading {
        font-size: 36px;
    }
    .para-width {
        width: 80%;
    }
}
@media (max-width: 767px) {
    .page-heading {
        font-size: 32px;
        text-shadow: 2px 1px black;
    }

    .para-width {
        width: 100%;
        font-size: 18px;
        line-height: normal;
        text-shadow: 1px 1px 1px black;
    }
}