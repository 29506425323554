.details-container{
    background-image: url(../../../public/assets/images/grunjy-bg-1.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    background-attachment: fixed;
}
section.blog-content {
    padding: 2rem;
    background: white;
    box-shadow: 0px 9px 7px 0px #80808030;
}
.blogs-container .content-card{
    row-gap: 1rem;
}
.blogs-container .blogs-text-wraper{
    row-gap: 1rem;
}
.blogs-container .card-heading{
    color: rgb(255, 0, 85);
    font-size: 35px;
    font-style: normal;
    font-family: 'Avenir';
    font-weight: 600;
    letter-spacing: -1.7px;
    line-height: normal;
}
.blogs-container .blogs-h2 {
    color: #2c2c2c;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    padding: 0;
}
.blogs-h3 {
    color: #2c2c2c;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    padding: 0;
    letter-spacing: -1px;
}
.blog-content a {
    text-decoration: none;
    color:rgb(255, 0, 85) ;
}
.blog-content a:hover {
    text-decoration: none;
    color:rgba(255, 0, 85, 0.733) ;
}
.blogs-text-wraper img {
    width: 100%;
}
.big-card-date{
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
}
.blogs-container .card-mid-p, .blogs-list{
    color: #2c2c2c;
    font-size: 20px;
    font-style: normal;
    font-weight: 400; 
}

.blogs-list::marker{
    font-size: 20px;
    color: black;
}


@media (max-width:1200px){
    section.blog-content {
        padding: 2rem;
    }
    .blogs-container .content-card{
        row-gap: 1rem;
    }
    .blogs-container .blogs-text-wraper{
        row-gap: 1rem;
    }
    .blogs-container .card-heading{
        font-size: 30px;
    }
    .blogs-container .blogs-h2 {
        font-size: 23px;
    }
    .blogs-h3{
        font-size: 18px;
    }
    .big-card-date{
        font-size: 16px;
    }
    .blogs-container .card-mid-p,
    .blogs-list{
        font-size: 18px;
    }
}
@media (max-width:760px){
    .blogs-container .card-heading{
        font-size: 25px;
    }
    section.blog-content {
        padding: 10px;
    }
}