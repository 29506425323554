.tranformation-carousel .item img {
    display: block;
    width: 100%;
    height: auto;
    aspect-ratio: 2/2;
    object-fit: cover;
}

.splide__pagination {
    bottom: -2rem;
}

.splide__arrow svg {
    fill: #f05;
}