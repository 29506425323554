.footer-head {
    background-image: url("../../../public/assets/images/motivating-pink.png");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0px 100px 0px;
    position: relative;
}

.footer-overlay {
    background-color: rgb(255 0 85 / 41%);
    opacity: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.footer-head .container {
    position: relative;
    z-index: 2;
}

.footer-banner-heading {
    color: #000000ba;
    font-size: 42px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.1em;
}

.footer-heading-span {
    color: #FFF;
}

.footer-banner-btn {
    min-height: 40px;
    display: inline-block;
    line-height: 1;
    background-color: #818a91;
    font-size: 15px;
    padding: 12px 24px;
    border-radius: 3px;
    color: #fff;
    fill: #fff;
    text-align: center;
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
    font-weight: 600;
    text-transform: uppercase;
    border-style: solid;
    border-radius: 0px 0px 0px 0px;
    padding: 20px 20px 20px 20px;
    background-color: #1B1B1B;
    color: #ffffff;
    border-color: #1B1B1B;
    width: 100%;
}

.footer-banner-btn:hover {
    background-color: #FFFFFF;
    color: #f05;
    border-color: #FFFFFF;
}

footer {
    padding: 60px 0px 60px 0px;
    background-color: #1B1B1B;
}

.footer-heading {
    font-size: 16px;
    line-height: 1em;
    color: #f05;
    text-transform: uppercase;
    font-size: 120%;
    font-weight: 700;
    margin-bottom: 1.5rem;
}

.footer-nav a {
    font-size: 16px;
    line-height: 1em;
    color: #FFFFFF;
    text-decoration: none;
    padding-top: 10px;
    padding-bottom: 10px;
}

.footer-nav a:hover {
    color: #f05;
}

.logo-fluid {
    width: 100%;
    height: auto;
}

.vertical-divider {
    border-style: solid;
    border-width: 1px 0px 0px 0px;
    border-color: #FFFFFFC2;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin-top: 40px;
    margin-bottom: 0px;
    padding: 40px 0px 0px 0px;
}

.footer-foot-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: start;
}

.footer-foot-text {
    color: #BFBFBF;
    font-size: 14px;
}

.footer-foot-link {
    color: #BFBFBF;
    text-decoration: none;
    font-size: 14px;
    line-height: 1em;
}

.footer-foot-link:hover {
    color: #FFFFFF;
}

@media (min-width: 767px) and (max-width: 1023px) {
    .footer-nav a {
        text-align: center;
    }
}

@media (max-width: 767px) {
    .footer-head {
        padding: 60px 0px 60px 0px;
    }

    .logo-fluid {
        width: 50%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .footer-nav a {
        font-size: 14px;
    }

    .footer-nav {
        margin-top: 28px;
    }

    .footer-foot-text {
        margin-bottom: 1rem;
    }

    .footer-foot-wrapper {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}