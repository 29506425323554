.hero-section {
  background-image: url("../../../public/assets/images/_DSC5925\ 1.jpg");
  background-position: center;
  background-size: cover;
  height: 100vh;
  position: relative;
}

/* .overlay {
    background-color: rgba(255, 0, 85, 0.175);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  } */

.flex-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  height: 100vh;
}

.hero-heading-thin {
  color: #FFFFFF;
  font-size: 24px;
  font-weight: 100;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 0;
  margin: 0;
  line-height: 1;
}

.hero-heading-stong {
  font-size: 60px;
}

.w-idth-c-ustom {
  width: 64%;
}

.appointment-button {
  font-weight: 600;
  text-transform: uppercase;
  background-color: transparent;
  border: 3px solid #FFFFFF;
  border-radius: 3px;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  padding: 15px 100px 15px 100px;
  margin: 30px 0px 0px 0px;
  transition: background-color 0.3s, color 0.3s;
}

.appointment-button:hover {
  background-color: white;
  color: #f05;
  animation: bobBounceAnimation .3s ease-out infinite,
    bounceAnimation 1.5s ease-in-out infinite;
}

.widi-intro-section {
  background-image: url("../../../public/assets/images/widi-intro.jpg");
  background-position: center;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
}

.second-hero-subheading {
  color: #FFFFFF;
  font-size: 29px;
  font-weight: 400;
  text-transform: uppercase;
}

.second-hero-mainheading {
  font-weight: 700;
  font-size: 255%;
  line-height: 1;
  color: #fff;
}

.second-hero-para {
  color: #FFFFFF;
  font-size: 18px;
  line-height: 1.5em;
  margin: 0;
}

.video-bg {
  position: relative;
  height: 100%;
  width: 100%;
}

.video-container {
  object-fit: cover;
  height: 75vh;
  width: 100%;
  margin-bottom: -9px;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.35);
  /* background-color: #ae607940; */
}

.transformation-section {
  background-image: url("../../../public/assets/images/naji-stunts-1.jpg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 120px 0px 120px 0px;
}

.transformation-heading {
  color: #FFFFFF;
  font-size: 48px;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0px 10px 40px 10px;
  text-align: center;
}

.transformation-heading span {
  font-weight: 700;
  font-size: 140%;
  color: #fff;
  line-height: 1;
}


.insta-section {
  background-image: url("../../../public/assets/images/bg-morelli-show.png");
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
  margin-bottom: -40px;
  padding: 40px 0px 60px 0px;
}

.insta-section-mainheading {
  padding: 0;
  margin: 0;
  line-height: 1;
  font-size: 2.5rem;
}

.insta-section-subheading {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5em;
  padding-top: 6px;
  margin: 0;
}

.insta-reel-card {
  display: block;
  width: 100%;
  position: relative;
  background-position: center;
  background-size: cover;
  height: 388px;
  border: 1px solid #f05;
  border-radius: 14px;
  overflow: hidden;
}

.insta-reel-thumnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.absolute-centered-play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 65px;
  height: auto;
}

.quotation-section {
  background-image: url("../../../public/assets/images/bg-featured-logos.png");
  background-position: center;
  background-size: cover;
  padding: 2.5rem 0 1.5rem 0;
}

.quotation-carousel {
  height: 100%;
}

/* .home-demo .item {
  background: #ff3f4d;
} */

.quotation-carousel .item h2 {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}

.quotation-carousel .item cite {
  display: block;
  text-align: center;
  color: #f05;
}

.blogs-section {
  background-image: url("../../../public/assets/images/naji-run-bg.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  padding: 2.5rem 0 2.5rem 0;
}

.blogs-main-heading {
  color: #FFFFFF;
  font-size: 60px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  text-align: center;
}

.blogs-para {
  color: #FFFFFF;
  font-size: 18px;
  line-height: 1.5em;
  text-align: center;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: 40px;
}

.blog-nav .nav-item {
  width: 25%;
}

.nav-tabs {
  border: solid 1px #fff;
  margin-bottom: 40px;
}

.blog-nav .nav-link {
  padding: 20px 60px;
  border: solid 1px #fff;
  text-align: center;
  width: 100%;
  margin: 0;
  color: #ffffff;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 0;
}

.blog-nav .nav-link.active {
  color: #f05;
  border-radius: 0;
  border-color: #fff;
}

.all-posts-btn {
  display: block;
  line-height: 1;
  font-size: 15px;
  border-radius: 3px;
  text-align: center;
  transition: all .3s;
  width: 35%;
  fill: #FFFFFF;
  color: #FFFFFF;
  background-color: #f05;
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-color: #f05;
  padding: 20px 20px 20px 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
}

@media (max-width:990px){
  .hero-heading-stong{
    font-size: 30px;
  }
  .hero-heading-thin{
    text-shadow: 2px 1px 0px black;
    font-size: 18px;
  }
  .quotation-section{
    padding: 1rem 0 1.5rem 0;
  }
  .quotation-carousel .item cite{
    font-size: 13px;
  }
  .quotation-carousel .item h2{
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .hero-section {
    min-height: 50vh;
    max-height: 65vh;
  }

  .flex-wrapper {
    min-height: 50vh;
    max-height: 65vh;
  }

  .w-idth-c-ustom {
    width: 100%;
  }

  .hero-heading-thin {
    font-size: 15px;
    line-height: 1.2em;
    letter-spacing: 0px;
  }

  .hero-heading-stong {
    font-size: 32px;
  }

  .appointment-button {
    width: 100%;
    font-size: 16px;
    padding: 15px 15px 15px 15px;
  }

  .widi-intro-section {
    background-position: top right;
    height: 100%;
    padding-bottom: 40px;
  }

  .second-hero-subheading {
    font-size: 24px;
    text-align: center;
  }

  .second-hero-para {
    font-size: 15px;
    text-align: center;
  }

  .transformation-heading {
    color: #FFFFFF;
    font-size: 48px;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0px 10px 40px 10px;
    text-align: center;
  }

  .transformation-heading {
    font-size: 24px;
  }

  .insta-reel-card {
    height: 250px;
  }

  .absolute-centered-play-btn {
    width: 50px;
    height: auto;
  }

  .all-posts-btn {
    width: 75%;
  }

  .nav-tabs {
    flex-direction: column;
  }

  .blog-nav .nav-item {
    width: 100%;
  }

  .blogs-main-heading {
    font-size: 36px;
  }
}

@keyframes bobBounceAnimation {
  100% {
    transform: translateY(-8px);
  }
}

@keyframes bounceAnimation {
  0% {
    transform: translateY(-8px);
  }

  50% {
    transform: translateY(-4px);
  }

  100% {
    transform: translateY(-8px);
  }
}