.page-para {
    text-decoration: none;
}

.page-para:hover {
    color: #fff;
}

.text-decoratrion-underline {
    text-decoration: underline;
}

.contact-form-wrapper {
    background-image: url("../../../public/assets/images/bg-morelli-show.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    width: 100%;
    padding-bottom: 100px;
    padding-top: 100px;
}

.form-card {
    padding: 60px 60px 60px 60px;
    background-color: #fff;
    box-shadow: 7px 7px 15px 1px rgba(0, 0, 0, 0.1);
}

.widi-form {
    border-width: 1px 1px 1px 1px;
    border-radius: 0px 0px 0px 0px;
    border: 1px solid #818a91;
    border-color: #000000;
    min-height: 40px;
    font-weight: 400;
    line-height: 3em;
    font-size: 15px;
}

.widi-form:focus {
    color: #212529;
    background-color: transparent;
    border-color: #000000;
    outline: 0;
    box-shadow: none;
}

.widi-form-label {
    padding-bottom: 10px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: #f05;
}

.submit-btn {
    font-weight: 600;
    text-transform: uppercase;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    border-radius: 0px 0px 0px 0px;
    padding: 16px 20px 16px 20px;
    width: 100%;
    background-color: #000000;
    color: #fff;
    transition: all 0.3s linear;
}

.submit-btn:hover {
    background-color: #00000000;
    color: #f05;
    border-color: #000000;
    transition: all 0.3s linear;
}

@media (max-width: 767px) {
    .form-card {
        padding: 80px 10px 40px 10px;
    }
}