/* custom font */

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url('/public/assets/fonts/AvenirLTStd-Book.woff2') format('woff2'), url('/public/assets/fonts/AvenirLTStd-Book.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url('/public/assets/fonts/AvenirLTStd-Roman.woff2') format('woff2'), url('/public/assets/fonts/AvenirLTStd-Roman.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: url('/public/assets/fonts/AvenirLTStd-Black.woff2') format('woff2'), url('/public/assets/fonts/AvenirLTStd-Black.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 600;
  font-display: auto;
  src: url('/public/assets/fonts/AvenirLTProHeavy.woff2') format('woff2'), url('/public/assets/fonts/AvenirLTProHeavy.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 100;
  font-display: auto;
  src: url('/public/assets/fonts/AvenirLTProLight.woff2') format('woff2'), url('/public/assets/fonts/AvenirLTProLight.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  font-display: auto;
  src: url('/public/assets/fonts/AvenirLTProMedium.woff2') format('woff2'), url('/public/assets/fonts/AvenirLTProMedium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: bold;
  font-display: auto;
  src: url('/public/assets/fonts/AvenirLTProBlack.woff2') format('woff2'), url('/public/assets/fonts/AvenirLTProBlack.woff') format('woff');
  font-display: swap;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Avenir', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: #f05;
}

/* pagination css */

.pagination {
  display: flex;
  justify-content: center;
  padding-left: 0;
  list-style: none;
  margin-top: 32px;
}

.page-link {
  position: relative;
  display: block;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  color: #FFFFFF;
}

.page-item.disabled .page-link {
  background-color: transparent;
}

.page-link:hover {
  background-color: transparent;
  color: #f05;
}

.activePagination {
  border: solid 1px #f05;
}

@media (min-width: 767px) and (max-width: 1023px) {
  .justify-fluid {
    justify-content: center;
  }
}