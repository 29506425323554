.naji-header {
    width: 100%;
}
.widad-logo {
    aspect-ratio: auto 800 / 207;
    width: 208px;
    height: auto;
    max-width: 100%;
    border: none;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.navbar {
    width: 100%;
}

.navbar-light .navbar-nav .nav-link,
.navbar-dark .navbar-nav .nav-link {
    color: #FFFFFF;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
    color: #FFFFFF;
}

.nav-link {
    position: relative;
    fill: #FFFFFF;
    padding-left: 0px !important;
    padding-right: 0px !important;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.2em;
    margin-left: calc(60px / 2);
    margin-right: calc(60px / 2);
}

.nav-link-active::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -3px;
    height: 3px;
    width: 100%;
    transform: translateX(-50%);
    background-color: #FFFFFF;
}
@media (max-width:990px){
    .naji-header {
        width: 100%;
        height: 104px;
        position: relative;
        padding: 0px;
        box-shadow: 0px -288px 0px 290px var(--header-bg);
        z-index: 2;
    }
    .widad-nav {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: var(--header-bg);
        padding: 10px;
    }
    .widad-nav .navbar-nav {
        gap: 1rem;
    }
    .widad-nav .naji-nav-left{
        background: var(--header-bg);
        padding-bottom: 1rem;
        margin-top: 9px;
    }
    .widad-nav .naji-nav-right{
        background: var(--header-bg);
        border-radius: 0px 0px 10px 10px;
        padding-bottom: 1rem;
    }
    .widad-nav .nav-link{
        width: fit-content;
    }
    .widad-nav .nav-link-active::after{
        content: "";
        position: absolute;
        left: 0;
        bottom: 6px;
        height: 2px;
        width: 100%;
        transform: unset;
        background-color: #FFFFFF; 
    }

}
@media (max-width: 767px) {
    .naji-header{
        height: 85px;  
    }
    .navbar-brand {
        margin-right: 0px;
    }

    .widad-logo {
        width: 176px;
    }

    .nav-link-active::after {
        bottom: 0;
    }
    .widad-nav .naji-nav-left{
        margin-top: 0.1rem;
    }
}